import React, { FunctionComponent } from "react";
import { DrawRectangleMode } from "react-map-gl-draw";
import { AreaRainfallRatePoint } from "../../functions/api";
import { useMapReducer, MapState, MapActionTypes, DateRangeType, ToggleEditorType, MapCursorType, defaultState, MIN_ZOOM } from "./useMapReducer";
import moment, { Moment } from 'moment';
import { InteractiveMapProps } from "react-map-gl";

export const MapContext = React.createContext<MapContextValue>(defaultState as MapContextValue);

export type MapContextValue = Readonly<{
    editorMode: DrawRectangleMode | undefined,
    mapCursor: MapCursorType,
    isEditorActive: boolean,
    editorFeatures: any[],
    dateRange: DateRangeType,
    point1: AreaRainfallRatePoint,
    point2: AreaRainfallRatePoint,
    viewport: InteractiveMapProps,
    markedPointLat: number;
    markedPointLong: number;

    setDateRange: (e?: any, picker?: any, dateStart?: Moment, dateEnd?: Moment) => void;
    toggleEditorMode: (e?: any, forceSelect?: boolean) => void,
    toggleAreaEditMode: (isEditorActive: boolean, mapCursor: MapCursorType, editorMode: DrawRectangleMode | undefined) => void,
    updateHeatmapData: (value: any) => void,
    onUpdate: (value: any) => void;
    setMapCursor: (type: MapCursorType) => void;
    toggleZoom: (type: "zoomIn" | "zoomOut") => void;
    setViewport: (nextViewport: any) => void;
    resetEditorFeatures: () => void;
    setMarkedPoints: (lat: number, long: number) => void;
    resetMarkedPointData: () => void;
    getDefaultTimeRange: (apiType: "PointRainfallRate" | "AreaRainfallRate") => void;

}>;

export type MapProviderProps = Readonly<{
    initialState?: MapState;
}>;

export const MapProvider: FunctionComponent<MapProviderProps> = (props) => {
    const { initialState }: MapProviderProps = props;
    const [state, dispatch] = useMapReducer(initialState);

    const setDateRange = (e?: any, picker?: any, dateStart?: Moment, dateEnd?: Moment) => {

        let st = dateStart ? dateStart : moment();
        let en = dateEnd ? dateEnd : moment()

        const start = picker ? moment(picker.startDate) : st;
        const end = picker ? moment(picker.endDate) : en;

        dispatch({ type: MapActionTypes.setDateRange, dateRange: { startDate: start, endDate: end } })
    };

    const onUpdate = (value: any) => {

        let data = value.data[value.data.length - 1];
        let dataForUpdate = [data];

        let update: ToggleEditorType = {
            editorFeatures: dataForUpdate,
            editorMode: new DrawRectangleMode(),
            isEditorActive: true,
            mapCursor: "crosshair"
        };

        dispatch({ type: MapActionTypes.onUpdate, update: update });
    };

    const toggleAreaEditMode = (isEditorActive: boolean, mapCursor: MapCursorType, editorMode: DrawRectangleMode | undefined) => {
        dispatch({ type: MapActionTypes.toggleAreaEditMode, isEditorActive: isEditorActive, mapCursor: mapCursor, editorMode: editorMode })
    }

    const toggleEditorMode = (e?: any, forceSelect?: boolean) => {
        let toggleEditor: ToggleEditorType = {
            editorFeatures: [],
            editorMode: new DrawRectangleMode(),
            isEditorActive: e ? e.currentTarget.checked : forceSelect,
            mapCursor: "grab"
        };

        if (e?.currentTarget.checked || forceSelect) {
            toggleEditor.editorFeatures = [];
            toggleEditor.editorMode = new DrawRectangleMode();
            toggleEditor.mapCursor = 'crosshair'
        } else {
            toggleEditor.editorMode = undefined;
            toggleEditor.mapCursor = 'grab'
        }

        dispatch({ type: MapActionTypes.toggleEditorMode, toggleEditor: toggleEditor });
    };

    const getDefaultTimeRange = (apiType: "PointRainfallRate" | "AreaRainfallRate") => {
        dispatch({ type: MapActionTypes.getDefaultTimeRange, apiType });
    };

    const updateHeatmapData = (value: any) => {
        const data = value.data[value.data.length - 1];
        const dataForUpdate = [data];
        const point1 = {
            latitude: dataForUpdate[0]?.geometry?.coordinates[0][1][1],
            longitude: dataForUpdate[0]?.geometry?.coordinates[0][1][0]
        };
        const point2 = {
            latitude: dataForUpdate[0]?.geometry?.coordinates[0][3][1],
            longitude: dataForUpdate[0]?.geometry?.coordinates[0][3][0]
        }

        dispatch({ type: MapActionTypes.updateHeatmapData, points: { point1: point1, point2: point2 } })
    };

    const setMapCursor = (type: MapCursorType) => {
        dispatch({ type: MapActionTypes.setMapCursor, cursor: type })
    }

    const toggleZoom = (type: "zoomIn" | "zoomOut") => {
        let nextViewport = state.viewport;

        if (!nextViewport.zoom) return

        if (type === "zoomIn") {
            nextViewport.zoom += 0.5
        }
        else {
            let zoomOutValue = nextViewport.zoom - 0.5;

            if (zoomOutValue >= MIN_ZOOM) {
                nextViewport.zoom -= 0.5
            };
        };

        dispatch({ type: MapActionTypes.toggleZoom, viewport: nextViewport })
    }

    const setViewport = (nextViewport: any) => {
        dispatch({ type: MapActionTypes.setViewport, viewport: { ...state.viewport, ...nextViewport } });
    }

    const resetEditorFeatures = () => {
        dispatch({ type: MapActionTypes.resetEditorFeatures })
    }

    const resetMarkedPointData = () => {
        dispatch({ type: MapActionTypes.resetMarkedPointData })
    }

    const setMarkedPoints = (lat: number, long: number) => {
        dispatch({ type: MapActionTypes.setMarkedPoints, lat: lat, long: long });
    };

    return (
        <MapContext.Provider value={{
            editorMode: state.editorMode,
            mapCursor: state.mapCursor,
            isEditorActive: state.isEditorActive,
            editorFeatures: state.editorFeatures,
            dateRange: state.dateRange,
            point1: state.point1,
            point2: state.point2,
            viewport: state.viewport,
            markedPointLat: state.markedPointLat,
            markedPointLong: state.markedPointLong,

            setDateRange,
            toggleEditorMode,
            updateHeatmapData,
            onUpdate,
            setMapCursor,
            toggleZoom,
            setViewport,
            resetEditorFeatures,
            setMarkedPoints,
            resetMarkedPointData,
            getDefaultTimeRange,
            toggleAreaEditMode
        }}>
            {props.children}
        </MapContext.Provider>
    );
};