// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-example-tsx": () => import("./../../../src/pages/example.tsx" /* webpackChunkName: "component---src-pages-example-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-rainfall-data-tsx": () => import("./../../../src/pages/rainfall-data.tsx" /* webpackChunkName: "component---src-pages-rainfall-data-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

