import { Moment } from "moment";
import React, { FunctionComponent } from "react";
import { AreaRainfallRatePoint, getAreaRainfallRate, getPointRainfallRate } from "../../functions/api";
import { useDataReducer, DataState, DataActionTypes, defaultState } from "./useDataReducer";
import moment from 'moment';
import { convertWorker } from '../../workers/convertWorker';
import { City, Endpoint } from "../../data/citiesDropDownData";
var PromisePool = require('es6-promise-pool')
import AWS from "aws-sdk";

AWS.config.region = "eu-central-1"; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "eu-central-1:f4a78e97-8294-4a9d-b357-7a4ef1730bf4"
});

const lambda = new AWS.Lambda();

export const DataContext = React.createContext<DataContextValue>(defaultState as DataContextValue);

export type DataContextValue = Readonly<{
    hasRainfall: boolean;
    loading: boolean;
    errorsList: string[];
    isFetchSuccess: boolean;
    dataForDownload: { json: string, geoJson: string };
    heatmapData: any;
    isJsonBodyVisible: boolean;
    activeCity: City;
    activeEndpoint: Endpoint | undefined;
    utc_unixtimestamp: string[];
    values: number[];
    apiCallNumber: number;

    setLoading: () => void,
    fetchData: (startDate: Moment, endDate: Moment, point1: AreaRainfallRatePoint, point2: AreaRainfallRatePoint) => void;
    fetchPointRainfallRate: (lat: number, long: number, startDate: Moment, endDate: Moment) => void;
    resetAllData: () => void;
    resetHeatMapData: () => void;
    selectCity: (newCity: City) => void;
    selectEndpoint: (newEndpoint: Endpoint) => void;
}>;

export type DataProviderProps = Readonly<{
    initialState?: DataState;
}>;

export const DataProvider: FunctionComponent<DataProviderProps> = (props) => {
    const { initialState }: DataProviderProps = props;
    const [state, dispatch] = useDataReducer(initialState);

    let responses: any = []
    let errors = 0;
    let errorsList: string[] = [];
    let isFetchSuccess = true;
    let success = 0;
    let utc_unixtimestamp: string[] = [];
    let values: number[] = [];

    const delayValue2 = (lat: any, long: any, value: any, time: any, t1: any, t2: any) => {

        return new Promise(function(resolve, reject){
            const body = {
                "request": "rainfall_rate_localized_timeseries_json",
                "wgs84_lat_deg": lat.toFixed(6).toString(),
                "wgs84_lon_deg": long.toFixed(6).toString(),
                "utc_unixtimestamp_start": t1.unix(),
                "utc_unixtimestamp_stop": t2.unix(),
                "utc_unixtimestamp_delta": 60
            };
    
            lambda.invoke(
                {
                    FunctionName:
                        "arn:aws:lambda:eu-central-1:007554147817:function:get_rainfall_rate_function",
                    Payload: JSON.stringify(body)
                },
                function (err, data) {
                    if (err) {
                        errorsList.push(`${t1.format("DD.MM.yyyy, HH:mm")} - ${t2.format("DD.MM.yyyy, HH:mm")}`)
                        errors += 1;
                        isFetchSuccess = false;
                        resolve(err)
                    } else {
                        if (data.Payload) {
                            let res = JSON.parse(JSON.parse(JSON.stringify(data.Payload)));
                            responses.push(res)
                            resolve(res)
                        };
                    };
                }
            );
        });
    };

    const fetchPointRainfallRate = async (lat: number, long: number, startDate: Moment, endDate: Moment) => {
        let requestsTimes: any = [];

        let apiCallNumber = Math.ceil(
            moment.duration(endDate.diff(startDate)).asHours() / 24
        );

        for (let i = 1; i <= apiCallNumber; i++) {
            let utc_unixtimestamp_start = startDate;
            let utc_unixtimestamp_stop = moment(startDate).startOf("hours").add(24, "hours");

            if (i === apiCallNumber) utc_unixtimestamp_stop = endDate;

            startDate = utc_unixtimestamp_stop;

            requestsTimes.push({
                utc_unixtimestamp_start: utc_unixtimestamp_start,
                utc_unixtimestamp_stop: utc_unixtimestamp_stop
            });
        };



        let count = 0

        // The number of promises to process simultaneously. 
        var concurrency = 10
        
        var promiseProducer = function () {
            if (count < apiCallNumber) {
                count++
                return delayValue2(lat, long, count, 1000, requestsTimes[count - 1].utc_unixtimestamp_start, requestsTimes[count - 1].utc_unixtimestamp_stop)
            } else {
                return null
            }
        }
        // // Create a pool. 
        var pool = new PromisePool(promiseProducer, concurrency);

        // Start the pool. 
        await pool.start();

        Promise.all(responses).then(responses => {
            // get all success responses 
            let response = responses.filter(value => value !== undefined).map(item => item);

            response.forEach((item: any) => {
                let timestamps = item.utc_unixtimestamp.map((time: number) => {
                    return moment.unix(time).format("DD/MM/YY, HH:mm");
                });

                utc_unixtimestamp = utc_unixtimestamp.concat(timestamps);
                values = values.concat(item.value)
            });

            dispatch({
                type: DataActionTypes.fetchPointRainfallRate, state:
                {
                    ...state,
                    isFetchSuccess: isFetchSuccess,
                    loading: false,
                    dataForDownload: { json: JSON.stringify(response), geoJson: "" },
                    isJsonBodyVisible: true,
                    activeCity: state.activeCity,
                    errorsList: errorsList,
                    utc_unixtimestamp: utc_unixtimestamp,
                    values: values,
                    activeEndpoint: state.activeEndpoint,
                    apiCallNumber: apiCallNumber,
                }
            });
        });
    };

    const delayValue = (point1: any, point2: any, value: any, time: any, t1: any, t2: any) => {
        return new Promise(function (resolve, reject) {
            console.log('Resolving ' + value + ' in ' + time + ' ms')
            setTimeout(function () {
                getAreaRainfallRate(
                    t1.unix(),
                    t2.unix(),
                    point1,
                    point2
                ).then(response => {
                    responses.push(response)
                    resolve(response);
                }).catch(e => {
                    errorsList.push(`${t1.format("DD.MM.yyyy, HH:mm")} - ${t2.format("DD.MM.yyyy, HH:mm")}`)
                    errors += 1;
                    isFetchSuccess = false;
                    resolve(e)

                });
            }, time)
        })
    }

    const fetchData = async (startDate: Moment, endDate: Moment, point1: AreaRainfallRatePoint, point2: AreaRainfallRatePoint) => {
        // get response and errors 

        let requestsTimes: any = [];

        let apiCallNumber = Math.ceil(
            moment.duration(endDate.diff(startDate)).asMinutes() / 5
        );

        for (let i = 1; i <= apiCallNumber; i++) {
            let utc_unixtimestamp_start = startDate;
            let utc_unixtimestamp_stop = moment(startDate).startOf("minutes").add(5, "minutes");

            if (i === apiCallNumber) utc_unixtimestamp_stop = endDate;

            startDate = utc_unixtimestamp_stop;

            requestsTimes.push({
                utc_unixtimestamp_start: utc_unixtimestamp_start,
                utc_unixtimestamp_stop: utc_unixtimestamp_stop
            });
        };

        let count = 0;

        // The number of promises to process simultaneously. 
        var concurrency = 4
        var promiseProducer = function () {
            if (count < apiCallNumber) {
                count++
                return delayValue(point1, point2, count, 1000, requestsTimes[count - 1].utc_unixtimestamp_start, requestsTimes[count - 1].utc_unixtimestamp_stop)
            } else {
                return null
            }
        }
        // Create a pool. 
        var pool = new PromisePool(promiseProducer, concurrency);

        // Start the pool. 
        await pool.start();

        await Promise.all(responses).then(async (responses) => {
            // get all success responses 
            let response = responses.filter(value => value !== undefined).map(item => item);

            // let convertedData = convertApiToGeoJson(response);
            let convertedData = {};
            if (typeof convertWorker !== 'boolean') {
                // @ts-ignore
                convertedData = await convertWorker().convert(response);
            }

            let hasRainfallRes = response.filter((item: any) => item.has_rainfall === true);
            let hasRainfall = false;

            if (hasRainfallRes && hasRainfallRes.length > 0)
                hasRainfall = true;
            else
                hasRainfall = false

            let heatmapData = convertedData;

            // Stringify data for download 
            const json = JSON.stringify(response, null, 4);
            const geoJson = JSON.stringify(convertedData, null, 4);
            dispatch({
                type: DataActionTypes.fetchData, state:
                {
                    isFetchSuccess: isFetchSuccess,
                    errorsList: errorsList,
                    hasRainfall: hasRainfall,
                    loading: false,
                    heatmapData: heatmapData,
                    dataForDownload: { json: json, geoJson: geoJson },
                    isJsonBodyVisible: true,
                    activeCity: state.activeCity,
                    activeEndpoint: state.activeEndpoint,
                    utc_unixtimestamp: [],
                    values: [],
                    apiCallNumber: 0,
                }
            });
        });
    };

    const setLoading = () => {
        let loaderValue = !state.loading
        dispatch({ type: DataActionTypes.setLoading, loaderValue: loaderValue });
    };

    const resetHeatMapData = () => {
        dispatch({ type: DataActionTypes.resetHeatMapData });
    }

    const resetAllData = () => {
        dispatch({ type: DataActionTypes.resetAllData });
    }

    const selectCity = (newCity: City) => {
        dispatch({ type: DataActionTypes.selectCity, newCity })
        dispatch({ type: DataActionTypes.selectEndpoint, newEndpoint: newCity.endpoints.find((item: Endpoint) => item.id === 1) })
    };

    const selectEndpoint = (newEndpoint: Endpoint) => {
        dispatch({ type: DataActionTypes.selectEndpoint, newEndpoint })
    };

    return (
        <DataContext.Provider value={{
            hasRainfall: state.hasRainfall,
            loading: state.loading,
            errorsList: state.errorsList,
            isFetchSuccess: state.isFetchSuccess,
            dataForDownload: state.dataForDownload,
            heatmapData: state.heatmapData,
            isJsonBodyVisible: state.isJsonBodyVisible,
            activeCity: state.activeCity,
            activeEndpoint: state.activeEndpoint,
            utc_unixtimestamp: state.utc_unixtimestamp,
            values: state.values,
            apiCallNumber: state.apiCallNumber,

            fetchData,
            setLoading,
            resetAllData,
            resetHeatMapData,
            selectCity,
            selectEndpoint,
            fetchPointRainfallRate,
        }}>
            {props.children}
        </DataContext.Provider>
    );
};
