import moment, { Moment } from 'moment';
import { useReducer } from 'react';
import { InteractiveMapProps } from 'react-map-gl';
import { DrawRectangleMode } from 'react-map-gl-draw';
import { AreaRainfallRatePoint } from '../../functions/api';

export const DEFAULT_START_DATE_AREA_API = moment().startOf("minutes").add(-5, "minutes");
export const DEFAULT_END_DATE_AREA_API = moment().startOf("minutes");
export const DEFAULT_START_DATE_POINT_API = moment().startOf("hours").add(-24, "hours");
export const DEFAULT_END_DATE_POINT_API = moment().startOf("hours");

const INITIAL_ZOOM = 8;
export const MIN_ZOOM = 7;

export function useMapReducer(initialState?: MapState) {
    return useReducer(reducer, initialState, initState);
}

/**
 * State.
 */
export type MapState = {
    editorMode: DrawRectangleMode | undefined;
    isEditorActive: boolean;
    mapCursor: MapCursorType;
    editorFeatures: any[];
    dateRange: DateRangeType;
    point1: AreaRainfallRatePoint;
    point2: AreaRainfallRatePoint;
    viewport: InteractiveMapProps;
    markedPointLat: number;
    markedPointLong: number;
};

export const defaultState: MapState = {
    editorMode: new DrawRectangleMode(),
    isEditorActive: true,
    mapCursor: "crosshair",
    editorFeatures: [],
    dateRange: { startDate: DEFAULT_START_DATE_AREA_API, endDate: DEFAULT_END_DATE_AREA_API },
    point1: { latitude: 0, longitude: 0 },
    point2: { latitude: 0, longitude: 0 },
    markedPointLat: 0,
    markedPointLong: 0,
    viewport: {
        width: '100%',
        height: 500,

        latitude: 51.92441,
        longitude: 4.47773,
        zoom: INITIAL_ZOOM,
        minZoom: MIN_ZOOM,

        mapStyle: 'mapbox://styles/igryski/ckh388eh31uyh19mihudeoc5w',
    }
};

const initState = (initialState?: MapState): MapState => {
    return initialState ? initialState : defaultState;
};

/**
 * Actions.
 */
export enum MapActionTypes {
    'setDateRange',
    'toggleEditorMode',
    'updateHeatmapData',
    'onUpdate',
    'setMapCursor',
    'resetEditorFeatures',
    'toggleZoom',
    'setViewport',
    'setMarkedPoints',
    'resetMarkedPointData',
    'getDefaultTimeRange',
    'toggleAreaEditMode'
};

export type MapAction =
    | { type: MapActionTypes.setDateRange; dateRange: DateRangeType }
    | { type: MapActionTypes.toggleEditorMode; toggleEditor: ToggleEditorType }
    | { type: MapActionTypes.updateHeatmapData; points: { point1: AreaRainfallRatePoint, point2: AreaRainfallRatePoint } }
    | { type: MapActionTypes.onUpdate; update: ToggleEditorType }
    | { type: MapActionTypes.setMapCursor; cursor: MapCursorType }
    | { type: MapActionTypes.resetEditorFeatures; }
    | { type: MapActionTypes.toggleZoom; viewport: InteractiveMapProps }
    | { type: MapActionTypes.setViewport; viewport: InteractiveMapProps }
    | { type: MapActionTypes.setMarkedPoints; lat: number, long: number }
    | { type: MapActionTypes.resetMarkedPointData; }
    | { type: MapActionTypes.toggleAreaEditMode; isEditorActive: boolean, mapCursor: MapCursorType, editorMode: DrawRectangleMode | undefined }
    | { type: MapActionTypes.getDefaultTimeRange; apiType: "PointRainfallRate" | "AreaRainfallRate" }
    ;

/**
 * Reducer.
 */
function reducer(state: MapState, action: MapAction): MapState {
    switch (action.type) {
        case MapActionTypes.setDateRange:
            return Object.freeze({
                ...state,
                dateRange: action.dateRange
            })

        case MapActionTypes.toggleEditorMode:
            return Object.freeze({
                ...state,
                editorFeatures: action.toggleEditor.editorFeatures,
                editorMode: action.toggleEditor.editorMode,
                isEditorActive: action.toggleEditor.isEditorActive,
                mapCursor: action.toggleEditor.mapCursor
            })

        case MapActionTypes.updateHeatmapData:
            return Object.freeze({
                ...state,
                point1: action.points.point1,
                point2: action.points.point2,
            })

        case MapActionTypes.onUpdate:
            return Object.freeze({
                ...state,
                editorFeatures: action.update.editorFeatures,
                editorMode: action.update.editorMode,
                isEditorActive: action.update.isEditorActive,
                mapCursor: action.update.mapCursor
            })

        case MapActionTypes.setMapCursor:
            return Object.freeze({
                ...state,
                mapCursor: action.cursor
            });

        case MapActionTypes.resetEditorFeatures:
            return Object.freeze({
                ...state,
                editorFeatures: []
            });

        case MapActionTypes.toggleZoom:
            return Object.freeze({
                ...state,
                viewport: action.viewport
            });

        case MapActionTypes.setViewport:
            return Object.freeze({
                ...state,
                viewport: action.viewport
            });

        case MapActionTypes.setMarkedPoints:
            return Object.freeze({
                ...state,
                markedPointLat: action.lat,
                markedPointLong: action.long
            });

        case MapActionTypes.resetMarkedPointData:
            return Object.freeze({
                ...state,
                markedPointLat: 0,
                markedPointLong: 0
            });
        case MapActionTypes.toggleAreaEditMode:
            return Object.freeze({
                ...state,
                isEditorActive: action.isEditorActive,
                mapCursor: action.mapCursor,
                editorMode: action.editorMode
            });

        case MapActionTypes.getDefaultTimeRange:

            let timeRange: DateRangeType = {
                startDate: moment(),
                endDate: moment(),
            };

            if (action.apiType === "AreaRainfallRate") {
                timeRange.startDate = DEFAULT_START_DATE_AREA_API;
                timeRange.endDate = DEFAULT_END_DATE_AREA_API;
            } else {
                timeRange.startDate = DEFAULT_START_DATE_POINT_API;
                timeRange.endDate = DEFAULT_END_DATE_POINT_API;
            }

            return Object.freeze({
                ...state,
                dateRange: timeRange
            });

        default:
            // throw new Error('Wrong action');
            return state;
    };
};

/**
 * Helper types 
 */
export type DateRangeType = {
    startDate: Moment;
    endDate: Moment;
};

export type ToggleEditorType = {
    mapCursor: MapCursorType,
    isEditorActive: boolean,
    editorMode: DrawRectangleMode | undefined,
    editorFeatures: any[]
}

export type MapCursorType = "grab" | "crosshair" | "grabbing"