import { PagesUrls } from '../../typings/PagesUrls';
import image from '../assets/rotterdam.svg'

export const citiesList: City[] = [
    {
        name: "Rotterdam",
        id: 1,
        text: "Cities rotterdam text",
        exploreDataLink: PagesUrls.RainfallData,
        communityForumLink: "https://discussion.urbanmeteo.org/group/rotterdam",
        termsOfUseLink: PagesUrls.TermsAndConditions,
        logo: image,
        endpoints: [
            {
                method: "POST", 
                type: "AreaRainfallRate", 
                apiUrl: "https://dmhlfqz1u8.execute-api.eu-central-1.amazonaws.com/dev/area-rainfall-rate", 
                name: "Area rainfall rate", id: 1, 
                text: "Rotterdam data type api text",   
                desc: "Rotterdam data type api description text",
                step1: "Rotterdam data type api description step1",
                step2: "Rotterdam data type api description step2",
                step3: "Rotterdam data type api description step3",
                step4: "Rotterdam data type api description step4",
                step5: "Rotterdam data type api description step5",
                step6: "Rotterdam data type api description step6",
                step7: "Rotterdam data type api description step7",
                tooltipText: "prvi " 
            }, 
            {
                method: "GET", 
                type: "PointRainfallRate", 
                apiUrl: "https://dmhlfqz1u8.execute-api.eu-central-1.amazonaws.com/dev/rijnmond_radar", 
                name: "Point rainfall rate", 
                id: 2, 
                text: "Rotterdam data type point api text", 
                tooltipText: "drugi ",
                desc: "Rotterdam data type point api description text",
                step1: "Rotterdam data type point api description step1",
                step2: "Rotterdam data type point api description step2",
                step3: "Rotterdam data type point api description step3",
                step4: "Rotterdam data type point api description step4",
                step5: "Rotterdam data type point api description step5",
                step6: "Rotterdam data type point api description step6",
                step7: "Rotterdam data type point api description step7",
            }, 
        ]
    },
];

export type City = {
    name: string;
    id: number;
    text: string;
    exploreDataLink: string;
    communityForumLink: string;
    termsOfUseLink: string;
    logo: string;
    endpoints: Endpoint[]
};

export type Endpoint = {
    apiUrl: string;
    name: string;
    id: number;
    text: string;
    method: "GET" | "POST";
    type: "AreaRainfallRate" | "PointRainfallRate";
    tooltipText: string;
    desc: string;
    step1: string;
    step2: string;
    step3: string;
    step4: string;
    step5: string;
    step6: string;
    step7: string;
}