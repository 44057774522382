const React = require("react")
const { DataProvider } = require("./src/states/dataContext")
const { MapProvider } = require("./src/states/mapContext")
// Logs when the client route changes
exports.onRouteUpdate = ({ location, prevLocation }) => {

}
// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {
    return <DataProvider><MapProvider>{element}</MapProvider></DataProvider>
}