import moment, { Moment } from "moment";


export async function getAreaRainfallRate(utcStart: number, utcStop: number, point1?: AreaRainfallRatePoint, point2?: AreaRainfallRatePoint) {
    const url = 'https://dmhlfqz1u8.execute-api.eu-central-1.amazonaws.com/dev/area-rainfall-rate';

    const jsonBody = {
        "utc_unixtimestamp_start": utcStart,
        "utc_unixtimestamp_stop": utcStop,
        "point1": point1,
        "point2": point2
    };

    const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(jsonBody),
    });

    if (response.status !== 200) return null;

    const data = await response.json();
    return data;
};

export const getPointRainfallRate = async (wgs84_lat_deg: number, wgs84_lon_deg: number, utc_unixtimestamp_start: number, utc_unixtimestamp_stop: number) => {

    var headers = new Headers();
    headers.append("x-api-key", "jZinjm9BEXGw7nG8pHUILpgKXpnKtb3rbJQBkOhFPVs=");
    headers.append("Authorization", "Basic eC1hcGkta2V5OmpaaW5qbTlCRVhHdzduRzhwSFVJTHBnS1hwbkt0YjNyYkpRQmtPaEZQVnM=");
    
    const url = convertPointRainfallRateUrl( wgs84_lat_deg, wgs84_lon_deg, utc_unixtimestamp_start, utc_unixtimestamp_stop );

    let response = await fetch(url, {
        method: 'GET',
        headers: headers,
    });

    console.log(response.status, "status")
    if (response.status !== 200) return null;

    const data = await response.json();
    console.log(data)
    return data;
};

// export const getFetchResponse = (startDate: Moment, endDate: Moment, point1: AreaRainfallRatePoint, point2: AreaRainfallRatePoint): RainfallFetchResponse => {

//     let rval: RainfallFetchResponse = {
//         success: 0,
//         errors: 0,
//         errorsList: [],
//         requests: [],
//     }

//     let requestsTimes = [];

//     let apiCallNumber = Math.ceil(
//         moment.duration(endDate.diff(startDate)).asMinutes() / 5
//     );

//     for (let i = 1; i <= apiCallNumber; i++) {
//         let utc_unixtimestamp_start = startDate;
//         let utc_unixtimestamp_stop = moment(startDate).startOf("minutes").add(5, "minutes");

//         if (i === apiCallNumber) utc_unixtimestamp_stop = endDate;

//         startDate = utc_unixtimestamp_stop;

//         requestsTimes.push({
//             utc_unixtimestamp_start: utc_unixtimestamp_start,
//             utc_unixtimestamp_stop: utc_unixtimestamp_stop
//         });
//     };

//     let requests: any = requestsTimes.map(time => {
//         let data = getAreaRainfallRate(
//             time.utc_unixtimestamp_start.unix(),
//             time.utc_unixtimestamp_stop.unix(),
//             point1,
//             point2
//         ).then(response => {
//             rval.success += 1;
//             if (response === null) {
//                 console.log(response, "response")
//             }
//             return response;
//         }).catch(e => {
//             rval.errorsList.push(`${time.utc_unixtimestamp_start.format("DD.MM.yyyy, HH:mm")} - ${time.utc_unixtimestamp_stop.format("DD.MM.yyyy, HH:mm")}`)
//             rval.errors += 1;
//         });

//         return data;
//     });

//     rval.requests = requests;
//     return rval;
// };


// HELPERS 
export const convertPointRainfallRateUrl = (wgs84_lat_deg: number, wgs84_lon_deg: number, utc_unixtimestamp_start: number, utc_unixtimestamp_stop: number) => {
    return `https://dmhlfqz1u8.execute-api.eu-central-1.amazonaws.com/dev/rijnmond_radar/?wgs84_lat_deg=${wgs84_lat_deg.toFixed(6).toString()}&wgs84_lon_deg=${wgs84_lon_deg.toFixed(6).toString()}&utc_unixtimestamp_start=${utc_unixtimestamp_start}&utc_unixtimestamp_stop=${utc_unixtimestamp_stop}&utc_unixtimestamp_delta=60&empty_if_zeroes=true`;
};

export function getJsonBody(data: JsonBodyData) {
    const jsonBody = `
    "point1": {
        "latitude": ${data.point1.latitude},
        "longitude": ${data.point1.longitude}
    },
    "point2": {
        "latitude": ${data.point2.latitude},
        "longitude": ${data.point2.longitude}
    }`

    const json = `{
    "utc_unixtimestamp_start": ${data.startDate},
    "utc_unixtimestamp_stop": ${data.endDate},
    ${jsonBody}
 }`;

    return json;
};


export type AreaRainfallRatePoint = {
    longitude: number,
    latitude: number,
};

export type JsonBodyData = {
    point1: {
        latitude: number,
        longitude: number
    },
    point2: {
        latitude: number,
        longitude: number,
    }
    startDate: number,
    endDate: number,
}

export type RainfallFetchResponse = {
    success: number,
    errors: number,
    errorsList: string[],
    requests: any,
};