import { useReducer } from 'react';
import { citiesList, City, Endpoint } from '../../data/citiesDropDownData';

export function useDataReducer(initialState?: DataState) {
    return useReducer(reducer, initialState, initState);
}

/**
 * State.
 */
export type DataState = {
    hasRainfall: boolean;
    loading: boolean;
    errorsList: string[];
    isFetchSuccess: boolean;
    dataForDownload: { json: string, geoJson: string };
    heatmapData: any;
    isJsonBodyVisible: boolean;
    activeCity: City;
    activeEndpoint: Endpoint | undefined;
    utc_unixtimestamp: string[];
    values: number[];
    apiCallNumber: number;
};

export const defaultState: DataState = {
    hasRainfall: true,
    loading: false,
    errorsList: [],
    isFetchSuccess: true,
    dataForDownload: { json: "", geoJson: "" },
    heatmapData: null,
    isJsonBodyVisible: false,
    activeCity: citiesList[0],
    activeEndpoint: citiesList[0].endpoints.find((item: Endpoint) => item.id === 1),
    utc_unixtimestamp: [],
    values: [],
    apiCallNumber: 0,
};

const initState = (initialState?: DataState): DataState => {
    return initialState ? initialState : defaultState;
};

/**
 * Actions.
 */
export enum DataActionTypes {
    'fetchData',
    'fetchPointRainfallRate',
    'setLoading',
    'resetAllData',
    'setErrors',
    'resetHeatMapData',
    'selectCity',
    'selectEndpoint'
};

export type DataAction =
    | { type: DataActionTypes.fetchData; state: DataState }
    | { type: DataActionTypes.setLoading; loaderValue: boolean }
    | { type: DataActionTypes.setErrors; errors: string[] }
    | { type: DataActionTypes.resetHeatMapData; }
    | { type: DataActionTypes.resetAllData; }
    | { type: DataActionTypes.selectCity; newCity: City }
    | { type: DataActionTypes.selectEndpoint; newEndpoint: Endpoint | undefined }
    | { type: DataActionTypes.fetchPointRainfallRate; state: DataState }
    ;

/**
 * Reducer.
 */
function reducer(state: DataState, action: DataAction): DataState {
    switch (action.type) {
        case DataActionTypes.fetchData:
            return action.state;

        case DataActionTypes.fetchPointRainfallRate:
            return action.state;

        case DataActionTypes.setLoading:
            return Object.freeze({
                ...state,
                loading: action.loaderValue
            });

        case DataActionTypes.resetAllData:
            let newState: DataState = {
                ...defaultState,
                activeCity: state.activeCity,
                activeEndpoint: state.activeEndpoint,
            }   
            return newState;

        case DataActionTypes.resetHeatMapData:
            return Object.freeze({
                ...state,
                heatmapData: defaultState.heatmapData
            });

        case DataActionTypes.setErrors:
            return Object.freeze({
                ...state,
                errorsList: action.errors
            });

        case DataActionTypes.selectCity:
            return Object.freeze({
                ...state,
                activeCity: action.newCity
            });

        case DataActionTypes.selectEndpoint:
            return Object.freeze({
                ...state,
                activeEndpoint: action.newEndpoint
            });

        default:
            return state;
    };
};